import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";

export const getPlacesData = async (type, sw, ne) => {
  try {
    //request
    const {
      data: { data },
    } = await axios.get(
      `https://travel-advisor.p.rapidapi.com/${type}/list-in-boundary`,
      {
        params: {
          bl_latitude: sw.lat,
          tr_latitude: ne.lat,
          bl_longitude: sw.lng,
          tr_longitude: ne.lng,
        },
        headers: {
          "X-RapidAPI-Host": "travel-advisor.p.rapidapi.com",
          "X-RapidAPI-Key":
            "8c8ca645d6mshd73a9a4e98b8666p138b7bjsn9085d1371b8e",
          //"2bb76d1a06mshb0942c5ec9091b6p11118ejsn4932c95e23c0",
        },
      }
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getWeatherData = async (lat, lng) => {
  try {
    //request weather
    const { data } = await axios.get(
      "https://community-open-weather-map.p.rapidapi.com/weather",
      {
        params: { lat, lon: lng },
        headers: {
          "X-RapidAPI-Host": "community-open-weather-map.p.rapidapi.com",
          "X-RapidAPI-Key":
            "2bb76d1a06mshb0942c5ec9091b6p11118ejsn4932c95e23c0",
        },
      }
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
};
